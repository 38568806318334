const messages = {
    uk: {
        "CV and Portfolio by Oleksandr Riabokin": "Олександр Рябокінь, резюме та портфоліо",
        "alex1rap, oleksandr riabokin, cv, portfolio, fullstack, php, js, developer": "alex1rap, олександр рябокінь, резюме, портфоліо, програміст, веб-розробник, php, js, fullstack",
        "Fullstack PHP/JS Developer": "Fullstack PHP/JS веб-розробник",
        "Oleksandr Riabokin": "Олександр Рябокінь",
        "Download as PDF": "Скачати як PDF",
        "About me": "Про мене",
        "Personal": "Особисте",
        "Nickname": "Псевдонім",
        "Name": "Ім'я",
        "Birth Date": "Дата народження",
        "July 20, 1998": "20 липня 1998",
        "Skills": "Навички",
        "Hard": "Жорсткі",
        "Soft": "М'які",
        "Self-learning, punctuality, non-conflict": "Самонавчання, пунктуальність, безконфліктність",
        "Contact": "Контакти",
        "City": "Місто",
        "Kyiv, Ukraine": "Київ, Україна",
        "Email": "Пошта",
        "Phone": "Телефон",
        "Experience": "Досвід роботи",
        "Stack": "Стек",
        "Responsibilities": "Обов'язки",
        "Releasing": "Реліз",
        "Manual testing": "Ручне тестування",
        "ZooZy Pet Life Assistant backend development and supporting": "Розробка та підтримка бекенду мобільного додатку ZooZy Pet Life Assistant",
        "Outsource/outstaff products development": "Аутсорс/аутстаф розробка веб-сайтів",
        "Outsource backend development and supporting for ZooZy Pet Life Assistant": "Аутсорсна разработка и поддержка бекенду мобільного додатку ZooZy Pet Life Assistant",
        "Outstaff products development using magento": "Аутстаф-розробка сайтів з використанням Magento",
        "E-Shop development and supporting": "Розробка та підтримка сайтів інтернет-магазинів",
        "Products development and supporting using own framework": "Розробка веб-додатків з використанням самописного фреймворку компанії",
        "Gloice geo-messenger backend development and supporting": "Розробка та підтримка бекенду мобільного гео-мессенджера Gloice",
        "Development and supporting sites for international events and exhibition": "Розробка й підтримка сайтів масштабних міжнародних заходів та виставок",
        "Development and supporting most popular context ad management platform Origami": "Розробка та підтримка Origami — лідируючої платформи з керування контекстною рекламою",
        "Now": "Нині",
        "Jan": "Січень",
        "Feb": "Лютий",
        "Mar": "Березень",
        "Apr": "Квітень",
        "May": "Травень",
        "Jun": "Червень",
        "Jul": "Липень",
        "Aug": "Серпень",
        "Sep": "Вересень",
        "Oct": "Жовтень",
        "Nov": "Листопад",
        "Dec": "Грудень",
        "Portfolio": "Портфоліо",
        "{Month}, {Year}": "{Month} {Year}"
    },
    ru: {
        "CV and Portfolio by Oleksandr Riabokin": "Александр Рябоконь, резюме и портфолио",
        "alex1rap, oleksandr riabokin, cv, portfolio, fullstack, php, js, developer": "alex1rap, александр рябоконь, резюме, портфолио, программист, веб-разработчик, php, js, fullstack",
        "Fullstack PHP/JS Developer": "Fullstack PHP/JS веб-разработчик",
        "Oleksandr Riabokin": "Александр Рябоконь",
        "Download as PDF": "Скачать в PDF",
        "About me": "Обо мне",
        "Personal": "Личное",
        "Nickname": "Псевдоним",
        "Name": "Имя",
        "Birth Date": "Дата рождения",
        "July 20, 1998": "20 июля 1998",
        "Skills": "Навыки",
        "Hard": "Жесткие",
        "Soft": "Мягкие",
        "Self-learning, punctuality, non-conflict": "Самообучаемость, пунктуальность, бесконфликтность",
        "Contact": "Контакты",
        "City": "Город",
        "Kyiv, Ukraine": "Киев, Украина",
        "Email": "Почта",
        "Phone": "Телефон",
        "Experience": "Опыт работи",
        "Stack": "Стек",
        "Responsibilities": "Обязанности",
        "Releasing": "Релиз",
        "Manual testing": "Ручное тестирование",
        "ZooZy Pet Life Assistant backend development and supporting": "Разработка и поддержка бекенду мобильного приложения ZooZy Pet Life Assistant",
        "Outsource/outstaff products development": "Аутсорс/аутстаф разработка веб-сайтов",
        "Outsource backend development and supporting for ZooZy Pet Life Assistant": "Аутсорсная разработка и поддержка бекенда мобильного приложения ZooZy Pet Life Assistant",
        "Outstaff products development using magento": "Аутстаф-разработка сайтов с использованием Magento",
        "E-Shop development and supporting": "Разработка и поддержка сайтов интернет-магазинов",
        "Products development and supporting using own framework": "Разработка веб-приложений с использованием самописного фреймворка компании",
        "Gloice geo-messenger backend development and supporting": "Разработка и поддержка бекенда мобильного гео-мессенджера Gloice",
        "Development and supporting sites for international events and exhibition": "Разработка и поддержка сайтов масштабных международных мероприятий и выставок",
        "Development and supporting most popular context ad management platform Origami": "Разработка и поддержка Origami — лидирующей платформы для управления контекстной рекламой",
        "Now": "Нине",
        "Jan": "Январь",
        "Feb": "Февраль",
        "Mar": "Март",
        "Apr": "Апрель",
        "May": "Май",
        "Jun": "Июнь",
        "Jul": "Июль",
        "Aug": "Август",
        "Sep": "Сентябрь",
        "Oct": "Октябрь",
        "Nov": "Ноябрь",
        "Dec": "Декабрь",
        "Portfolio": "Портфолио",
        "{Month}, {Year}": "{Month} {Year}"
    },
}

const getLocale = () => {
    const defaultLocale = "en"
    const url = new URL(window.location.href)
    let matched = url.pathname.match(/^\/([a-z]{2})\//)
    if (matched) {
        let locale = matched[1]
        if (Object.keys(messages).includes(locale)) {
            if (document.documentElement.lang !== locale) {
                document.documentElement.lang = locale;
            }
        } else {
            document.documentElement.lang = defaultLocale
        }
    }
    return document.documentElement.lang
}

const trans = (message) => {
    const locale = getLocale()
    if (!messages[locale]) {
        return message
    }
    message = transMonth(message);
    if (!messages[locale][message]) {
        return message
    }
    return messages[locale][message]
}

const transMonth = (message) => {
    const locale = getLocale()
    let data, month, year
    data = message.match(/([a-z]{3})\w*, (\d{4})/i)
    if (data) {
        month = data[1]
        year = data[2]
        if (!messages[locale]["{Month}, {Year}"] || !messages[locale][month]) {
            return message
        }
        let pattern = messages[locale]["{Month}, {Year}"]
        month = messages[locale][month]
        message = pattern.replaceAll("{Month}", month).replaceAll("{Year}", year)
    }
    return message
}

module.exports = {
    trans,
    getLocale
}
