import {Component} from "react";

const Translator = require("../translator/Translator");

export default class Header extends Component {
    getHomeUrl() {
        switch (Translator.getLocale()) {
            case "uk":
                return "/uk/"
            case "ru":
                return "/ru/"
            default:
                return "/"
        }
    }

    render() {
        return (
            <div className="container transparent-dark">
                <div className="container navbar">
                    <div className="navbar-brand p-3">
                        <a href={this.getHomeUrl()} rel="nofollow" className="nav-link link-light">
                            {Translator.trans("Oleksandr Riabokin")}
                        </a>
                    </div>
                    <div className="navbar nav btn-group">
                        {
                            [
                                {name: 'En', path: '/'},
                                {name: 'Uk', path: '/uk/'},
                                {name: 'Ru', path: '/ru/'},
                            ].map(link => {
                                if (Translator.getLocale() === link.name.toLowerCase()) {
                                    return (
                                        <div className="btn disabled text-bg-dark link-light">{link.name}</div>
                                    )
                                }
                                return (
                                    <a href={link.path} rel="alternate" hrefLang={link.name.toLowerCase()}
                                       className="btn btn-outline-light">{link.name}</a>
                                );
                            })
                        }
                    </div>
                    <div className="navbar-nav">
                        <a className="btn btn-outline-light"
                           href="/pdf/Oleksandr_Riabokin_-_Fullstack_PHP_JS_Developer.pdf"
                           rel="nofollow"
                           type="application/pdf"
                           download="Oleksandr_Riabokin_-_Fullstack_PHP_JS_Developer.pdf"
                           target="_blank">
                            {Translator.trans("Download as PDF")}
                        </a>
                    </div>
                </div>
            </div>
        );
    }
}