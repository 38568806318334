import Line from "../page/Line";
import Row from "../page/Experience/Row";

const Translator = require("../translator/Translator");

export default function Experience() {
    return (
        <div className="app-cv-experience mb-5">
            <Line title={Translator.trans("Experience")}></Line>

            <div className="container">
                <Row title="ZooZy"
                     link="https://zoozy.co/"
                     image="/images/experience/zoozy.webp"
                     from={Translator.trans("June, 2022")}
                     to={Translator.trans("Now")}
                     stack="PHP, JS, Symfony, Sylius, Docker, ElasticSearch, Percona, PostgreSQL, Firebase Cloud Messaging"
                     responsibilities={[
                         Translator.trans("ZooZy Pet Life Assistant backend development and supporting"),
                         Translator.trans("Releasing"),
                         Translator.trans("Manual testing")
                     ]}
                />
                <Row title="Black Cat Technology"
                     link="https://bcat.tech/"
                     image="/images/experience/bcat.webp"
                     from={Translator.trans("May, 2022")}
                     to={Translator.trans("October, 2022")}
                     stack="PHP, JS, Cake-PHP, Laravel, WordPress, MySQL"
                     responsibilities={[
                         Translator.trans("Outsource/outstaff products development"),
                         Translator.trans("Manual testing")
                     ]}
                />
                <Row title="Dedicatted"
                     link="https://dedicatted.com/"
                     image="/images/experience/dedicatted.webp"
                     from={Translator.trans("May, 2021")}
                     to={Translator.trans("February, 2022")}
                     stack="PHP, JS, Symfony, Sylius, Docker, ElasticSearch, Percona, Firebase Cloud Messaging"
                     responsibilities={[
                         Translator.trans("Outsource backend development and supporting for ZooZy Pet Life Assistant"),
                         Translator.trans("Releasing"),
                         Translator.trans("Manual testing")
                     ]}
                />
                <Row title="MageDev Group"
                     image="/images/experience/magedev_2.webp"
                     link="https://magedevgroup.com/"
                     from={Translator.trans("March, 2021")}
                     to={Translator.trans("April, 2021")}
                     stack="PHP, JS, Magento, MySQL"
                     responsibilities={[
                         Translator.trans("Outstaff products development using magento")
                     ]}
                />
                <Row title="WebbyLab"
                     link="https://webbylab.com/"
                     image="/images/experience/webbylab.webp"
                     from={Translator.trans("December, 2020")}
                     to={Translator.trans("February, 2021")}
                     stack="PHP, JS, Slim Framework, MySQL"
                     responsibilities={[
                         Translator.trans("E-Shop development and supporting")
                     ]}
                />
                <Row title="Varteq"
                     link="https://varteq.com/"
                     image="/images/experience/varteq.webp"
                     from={Translator.trans("October, 2020")}
                     to={Translator.trans("December, 2021")}
                     stack="PHP, JS, MySQL"
                     responsibilities={[
                         Translator.trans("Products development and supporting using own framework")
                     ]}
                />
                <Row title="WebAnatomia"
                     link="http://webanatomia.com/"
                     image="/images/experience/webanatomia.webp"
                     from={Translator.trans("December, 2019")}
                     to={Translator.trans("September, 2020")}
                     stack="PHP, JS, Docker, Symfony, NodeJS, SocketIO, MySQL, Redis, MongoDB, Firebase Cloud Messaging"
                     responsibilities={[
                         Translator.trans("Gloice geo-messenger backend development and supporting"),
                         Translator.trans("Releasing"),
                         Translator.trans("Manual testing")
                     ]}
                />
                <Row title="Smile-Expo"
                     link="https://smileexpo.eu/"
                     image="/images/experience/smile_expo.webp"
                     from={Translator.trans("June, 2019")}
                     to={Translator.trans("October, 2019")}
                     stack="PHP, JS, Yii2, MySQL"
                     responsibilities={[
                         Translator.trans("Development and supporting sites for international events and exhibition")
                     ]}
                />
                <Row title="I-Media"
                     link="https://www.i-media.ru/"
                     image="/images/experience/i_media.webp"
                     from={Translator.trans("December, 2018")}
                     to={Translator.trans("May, 2019")}
                     stack="PHP, JS, Symfony, MySQL, RabbitMQ"
                     responsibilities={[
                         Translator.trans("Development and supporting most popular context ad management platform Origami")
                     ]}
                />
            </div>
        </div>
    );
};