import Line from "../page/Line";

const Translator = require("../translator/Translator");

export default function Personal(props) {
    return (
        <div className="app-cv-personal mb-5">
            <Line
                title={Translator.trans("About me")}
                subtitle={Translator.trans("Fullstack PHP/JS Developer")}
            ></Line>
            <div className="row container">
                <div className="row">
                    <div className="col-md-4">
                        <div className="img-thumbnail portfolio-item">
                            <img className="card-img profile-img"
                                 src="/images/profile_picture.webp"
                                 alt="Profile"/>
                        </div>
                    </div>
                    <div className="col-md-8">
                        <table className="">
                            <tbody className="table table-borderless table-hover bg-gradient table-dark">
                            <tr>
                                <td colSpan="2">
                                    <h3>{Translator.trans("Personal")}</h3>
                                </td>
                            </tr>
                            <tr>
                                <td className="p-2">{Translator.trans("Nickname")}</td>
                                <td className="text-gray p-2">Alex1RAP</td>
                            </tr>
                            <tr>
                                <td className="p-2">{Translator.trans("Name")}</td>
                                <td className="text-gray p-2">{Translator.trans("Oleksandr Riabokin")}</td>
                            </tr>
                            <tr>
                                <td className="p-2">{Translator.trans("Birth Date")}</td>
                                <td className="text-gray p-2">{Translator.trans("July 20, 1998")}</td>
                            </tr>
                            <tr>
                                <td colSpan="2">
                                    <h3>{Translator.trans("Skills")}</h3>
                                </td>
                            </tr>
                            <tr>
                                <td className="p-2">{Translator.trans("Hard")}</td>
                                <td className="text-gray p-2">
                                    PHP (5.6, 7.x, 8.x, Symfony), JS/TS (Native, jQuery, React, Angular, NodeJS), SQL
                                    (MySQL, PostgreSQL), NoSQL (Redis, MongoDB), Socket.IO, FCM, Docker, Linux, Git,
                                    RabbitMQ
                                </td>
                            </tr>
                            <tr>
                                <td className="p-2">{Translator.trans("Soft")}</td>
                                <td className="text-gray p-2">
                                    {Translator.trans("Self-learning, punctuality, non-conflict")}
                                </td>
                            </tr>
                            <tr>
                                <td colSpan="2">
                                    <h3>{Translator.trans("Contact")}</h3>
                                </td>
                            </tr>
                            <tr>
                                <td className="p-2">{Translator.trans("City")}</td>
                                <td className="text-gray p-2">{Translator.trans("Kyiv, Ukraine")}</td>
                            </tr>
                            <tr>
                                <td className="p-2">{Translator.trans("Email")}</td>
                                <td className="text-gray">
                                    <a href="mailto:alex1rap.wen.ru@gmail.com"
                                       className="nav-link link-light"
                                       target="_blank"
                                       rel="noreferrer">alex1rap.wen.ru@gmail.com</a>
                                </td>
                            </tr>
                            <tr>
                                <td className="p-2">{Translator.trans("Telegram")}</td>
                                <td className="text-gray">
                                    <a href="https://t.me/alex1rap"
                                       className="nav-link link-light"
                                       target="_blank"
                                       rel="noreferrer">alex1rap</a>
                                </td>
                            </tr>
                            <tr>
                                <td className="p-2">{Translator.trans("Phone")}</td>
                                <td className="text-gray">
                                    <a href="tel:+380633656140"
                                       className="nav-link link-light"
                                       target="_blank"
                                       rel="noreferrer">+380 63 36 56 140</a>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
};